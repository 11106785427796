import React, { useState, useEffect, useContext, useMemo } from "react";
import { toast } from "react-toastify";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { getDistance } from "geolib";
import * as classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import FormInput from "components/ui/FormContent/formInput";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormSelect from "components/ui/FormContent/formSelect";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import ErrorMessageDialog from "pages/quotePage/dialog/errorMessageDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TicketIcon from "@material-ui/icons/Receipt";
import LocationIcon from "@material-ui/icons/MyLocation";
import LocationOff from "@material-ui/icons/LocationOff";
import IconLink from "@material-ui/icons/ArrowForward";
import Alert from "@material-ui/lab/Alert";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import ErrorDialog from "components/ui/dialog/errorDialog";
import WorkticketStatusChip from "components/ui/Worktickets/WorkticketStatusChip";
import BadgeComponent from "components/common/Badge/index";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import { logException } from "components/util/logUtil";
import { DialogMessage } from "components/ui/core/dialogMessage";
import WorkticketComplianceChip from "components/ui/Worktickets/WorkticketComplianceChip";
import { loadGeo, getGeoLocation } from "components/util/geoUtil";
import { getServiceTypeDetails } from "components/util/getServiceTypeUtil";
import WorkticketBillingPartner from "./workticketBillingPartner";
import {
  convertDateFormatField,
  billingLimitDate,
} from "components/util/timeFormat";
import {
  workticketsStatus,
  workticketsRestrictStatus,
  workticketsCancellationReasonOptions,
  performTypeOptions,
  performTypePayableOptions,
} from "constants.js";
import {
  getRepairInfo,
  getRepairApplyCustomers,
} from "services/customerService";
import {
  updateWorkticketDetails,
  assignUserWorkticket,
  removeUserWorkticket,
  clockInWorkticketNonStrict,
  clockOutWorkticket,
  clockPreviewWorkticket,
  updateWorkticketStatus,
  addWorkticketComment,
  recallWorkticketStatus,
  changePartner,
  acceptWorkticketCO,
  approveWorkticketCO,
  getWorkticketDetails,
} from "services/workticketService";
import { getUserSchedulesAll, getUserJobSchedules } from "services/userService";
import { updatePerformType } from "services/projectService";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import useStyles from "./styles";

const addNewItemIfNotExists = (options, value, label = value) => {
  if (!options.find((item) => item.value === value)) {
    return [...options, { value, label }];
  }
  return options;
};

const WorkticketInformationCO = ({ refreshWorkticketData }) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const { permissions, users, role } = globalUi;
  const [stateContext, dispatchContext] = useWorkticketView();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [assignedTo, setAssignedTo] = useState([]);
  const [assignedToInHouse, setAssignedToInHouse] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [cancelReasonOptions, setCancelReasonOptions] = useState([]);
  const [cancelSubReasonOptions, setCancelSubReasonOptions] = useState([]);
  const [cancellationReasonSelected, setCancellationReasonSelected] =
    useState(null);
  const [cancellationSubReasonSelected, setCancellationSubReasonSelected] =
    useState(null);
  const [hasGeo, setHasGeo] = useState(false);
  const [positionLocation, setPositionLocation] = useState(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [openStatusUpdate, setOpenStatusUpdate] = useState(false);
  const [openRecall, setOpenRecall] = useState(false);
  const [openDone, setOpenDone] = useState(false);
  const [openValidation, setOpenValidation] = useState(false);
  const [typeValidation, setTypeValidation] = useState(0);
  const [statusUpdateType, setStatusUpdateType] = useState(null);
  const [openMessageError, setOpenMessageError] = useState(false);
  const [message, setMessage] = useState("");
  const [performBy, setPerformBy] = useState(null);
  const [performByPayable, setPerformByPayable] = useState(null);
  const [externalStatus, setExternalStatus] = useState("");
  const [repairCategory, setRepairCategory] = useState("");
  const [repairCode, setRepairCode] = useState("");
  const [repairCodeOptions, setRepairCodeOptions] = useState([]);
  const [repairCategoryOptions, setRepairCategoryOptions] = useState([]);
  const [openRepairDialog, setOpenRepairDialog] = useState(false);
  const [customerApplyRepair, setCustomerApplyRepair] = useState([]);
  const [markCompleted, setMarkCompleted] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [cancellationSubReason, setCancellationSubReason] = useState("");
  const [cancellationReasonNote, setCancellationReasonNote] = useState("");
  const [isLoadingStatusU, setIsLoadingStatusU] = useState(false);
  const [isLoadingRecall, setIsLoadingRecall] = useState(false);
  const [isLoadingValidation, setIsLoadingValidation] = useState(false);
  const [disabledStart, setDisabledStart] = useState(false);
  const [activeStart, setActiveStart] = useState(false);
  const [currentClock, setCurrentClock] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [msgValidation, setMsgValidation] = useState(null);
  const [dateDone, setDateDone] = useState(null);
  const [confirmationDate, setConfirmationDate] = useState(false);
  const [confirmationIsLoading, setConfirmationIsLoading] = useState(false);
  const [openClockOut, setOpenClockOut] = useState(false);
  const [isLoadingClockOut, setIsLoadingClockOut] = useState(false);
  const [isClosingClockOut, setIsClosingClockOut] = useState(false);
  const [externalNote, setExternalNote] = useState("");
  const [partnerAssigned, setPartnerAssigned] = useState(null);
  const [partners, setPartners] = useState([]);
  const [approveValidation, setApproveValidation] = useState(null);
  const [openApprove, setOpenApprove] = useState(false);

  const { workticket, isLoading } = stateContext ?? null;

  const TEMP_STAFFING_PROJECT = 5;
  const PORTFOLIO_PROJECT = 3;
  const CONTRACT_PERIODIC = 6;

  const permOnlyAP = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_ONLY_AP, permissions),
    [permissions]
  );
  const permAP = useMemo(
    () => hasPermission(permissionWorkticket.VIEW_AP, permissions),
    [permissions]
  );

  const usersAssigned = useMemo(() => {
    return (workticket.recurrence_instance_id &&
      workticket.preventative_service) ||
      (workticket?.preventative_service &&
        !workticket?.preventative_service[0].is_recurring &&
        workticket?.workticket_category !== 7)
      ? workticket.users.filter(
          (user) =>
            user.tier_classification !== "subcontractor" &&
            user?.role?.tier_classification !== "subcontractor"
        )
      : workticket.users;
  }, [workticket]);

  // Function to initialize the cancellation options
  const initializeCancellationOptions = (workticket) => {
    const { cancellation_reason, cancellation_sub_reason } = workticket;

    const parentReason = workticketsCancellationReasonOptions.find(
      (item) => item.value === cancellation_reason
    );

    // Handle cancellation reason
    const updatedReasonOptions = addNewItemIfNotExists(
      workticketsCancellationReasonOptions,
      cancellation_reason
    );
    setCancelReasonOptions(updatedReasonOptions);
    if (cancellation_reason) {
      setCancellationReasonSelected(cancellation_reason);
    }

    // Handle cancellation sub-reason (only if parent reason exists)
    if (parentReason) {
      const updatedSubReasonOptions = addNewItemIfNotExists(
        parentReason.subreasons,
        cancellation_sub_reason
      );
      setCancelSubReasonOptions(updatedSubReasonOptions);
      if (cancellation_sub_reason) {
        setCancellationSubReasonSelected(cancellation_sub_reason);
      }
    }
  };

  const handleClockOutConfirmationClose = () => {
    setIsClosingClockOut(true);
  };

  const handleRepairDialogClose = () => {
    setOpenRepairDialog(false);
  };

  useEffect(() => {
    const executeGeo = async () => {
      if (loadGeo()) {
        const position = await getGeoLocation();
        if (position) {
          setHasGeo(true);
          setPositionLocation(position);
        }
      } else {
        setHasGeo(false);
      }
    };
    executeGeo();
  }, []);

  useEffect(() => {
    const dataRepair = async () => {
      const resultRepair = await getRepairInfo();
      const repairCategories = resultRepair.data.data;
      const repairCategoriesOptions = repairCategories.map((item) => ({
        value: item.Id,
        label: item.Name,
        codes: item.Codes.map((code) => ({
          value: code.Id,
          label: code.Name,
        })),
      }));
      setRepairCategoryOptions(repairCategoriesOptions);
    };
    dataRepair();
  }, []);

  useEffect(() => {
    const dataCustomerApply = async () => {
      const resultCustomerApply = await getRepairApplyCustomers();
      const repairApplyCustomers = resultCustomerApply.data.data;
      const repairApplyCustomersOptions = repairApplyCustomers.map((item) =>
        parseInt(item.customer_id)
      );
      setCustomerApplyRepair(repairApplyCustomersOptions);
    };
    dataCustomerApply();
  }, []);

  useEffect(() => {
    if (workticket?.payment?.user_id && partners.length) {
      const selPartner = partners.find(
        (p) => p.id === workticket.payment.user_id
      );
      setPartnerAssigned(selPartner);
    }
  }, [workticket?.payment?.user_id, partners]);

  useEffect(() => {
    if (partnerAssigned) {
      const usersListInsert = users.filter(
        (user) =>
          (user.tier_classification === "subcontractor_crew" ||
            user?.role?.tier_classification === "subcontractor_crew") &&
          parseFloat(user.crew_id) === parseFloat(partnerAssigned.id)
      );
      setUsersList(usersListInsert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerAssigned]);

  useEffect(() => {
    if (!isLoading && isLoadingUsers) {
      let assigned = [];
      let assignedId = [];
      usersAssigned.map((user) => {
        const userFound = users.find((uItem) => uItem.id === user.id);
        if (userFound) {
          assigned.push(userFound);
        }
        assignedId.push(user.id);
        return true;
      });
      setAssignedTo(assigned);
      let usersListInsert = [];
      if (
        (workticket.recurrence_instance_id &&
          workticket.preventative_service) ||
        (workticket?.preventative_service &&
          !workticket?.preventative_service[0].is_recurring &&
          workticket?.workticket_category !== 7)
      ) {
        if (workticket.preventative_service[0].work_type === 2) {
          const partnersList = users
            .filter(
              (user) =>
                user.tier_classification === "subcontractor" ||
                user?.role?.tier_classification === "subcontractor"
            )
            .filter(
              (user) =>
                user.states &&
                user.states.split(",").includes(workticket.job.state)
            );
          setPartners(partnersList);

          if (partnerAssigned) {
            usersListInsert = users.filter(
              (user) =>
                (user.tier_classification === "subcontractor_crew" ||
                  user?.role?.tier_classification === "subcontractor_crew") &&
                parseFloat(user.crew_id) === parseFloat(partnerAssigned.id)
            );
          } else {
            usersListInsert = users.filter(
              (user) =>
                (user.tier_classification === "subcontractor_crew" ||
                  user?.role?.tier_classification === "subcontractor_crew") &&
                partnersList
                  .map((p) => parseFloat(p.id))
                  .includes(parseFloat(user.crew_id))
            );
          }
        } else {
          usersListInsert = users.filter(
            (user) =>
              user.tier_classification !== "subcontractor" &&
              user?.role?.tier_classification !== "subcontractor" &&
              user.tier_classification !== "subcontractor_crew" &&
              user?.role?.tier_classification !== "subcontractor_crew"
          );
        }
      } else {
        usersListInsert = users;
      }

      const usersListInHouseInsert = users.filter(
        (user) =>
          user.tier_classification !== "subcontractor" &&
          user?.role?.tier_classification !== "subcontractor" &&
          user.tier_classification !== "subcontractor_crew" &&
          user?.role?.tier_classification !== "subcontractor_crew"
      );

      setAssignedToInHouse(usersListInHouseInsert);
      setUsersList(usersListInsert);
      setIsLoadingUsers(false);
      setPerformBy(Number(workticket.external?.perform_by));
      setPerformByPayable(Number(workticket.external?.perform_by_payable));
      // Cancellation reason options
      // const findReasonC = workticketsCancellationReasonOptions.find(
      //   (item) => item.value === workticket.cancellation_reason
      // );
      // if (findReasonC || !workticket.cancellation_reason) {
      //   setCancelReasonOptions(workticketsCancellationReasonOptions);
      //   if (findReasonC) {
      //     setCancellationReasonSelected(findReasonC.value);
      //   }
      // } else {
      //   const insertReasonC = {
      //     value: workticket.cancellation_reason,
      //     label: workticket.cancellation_reason,
      //   };
      //   setCancelReasonOptions([
      //     ...workticketsCancellationReasonOptions,
      //     insertReasonC,
      //   ]);
      //   setCancellationReasonSelected(workticket.cancellation_reason);
      // }
      initializeCancellationOptions(workticket);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workticket, isLoading, isLoadingUsers, usersAssigned, assignedTo, users]);

  useEffect(() => {
    // Clock in verification on this workticket
    const runningClocks = async () => {
      if (!isLoading) {
        try {
          const user = JSON.parse(localStorage.getItem("user"));
          const result = await getUserSchedulesAll(user.id);
          const data = result.data.data;
          const entry = data.entries.find(
            (item) =>
              item.workticket.id === workticket.id &&
              !item.clock_hours &&
              item.user.id === user.id
          );
          if (entry && entry.drive_in && entry.user.id === user.id) {
            setDisabledStart(true);
          } else {
            setDisabledStart(false);
          }
          if (entry && entry.clock_in && entry.user.id === user.id) {
            setActiveStart(true);
            setCurrentClock(entry);
          } else {
            setActiveStart(false);
            setCurrentClock(null);
          }
        } catch (e) {
          logException(e, "No timekeeping");
        }
      }
    };
    if (
      (globalUi.timekeeping && !currentClock) ||
      (!globalUi.timekeeping && currentClock) ||
      currentClock?.workticket?.id !== workticket.id
    ) {
      runningClocks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, workticket.id, globalUi.timekeeping]);

  useEffect(() => {
    if (workticket.id) {
      setIsLoadingUsers(true);
    }
  }, [workticket]);

  const handleBlur = async (event) => {
    if (!hasPermission(permissionWorkticket.EDIT, permissions)) {
      return;
    }
    if (workticket[event.name] !== event.value) {
      try {
        const data = {
          [event.name]: event.value,
        };
        await updateWorkticketDetails(workticket.id, data);
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: { ...workticket, ...data },
        });
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          setOpenMessageError(true);
          setMessage(e.response.data.message);
        }
        logException(e, "Cannot update workticket");
      }
    }
  };

  const closeMessageError = () => {
    setOpenMessageError(false);
  };

  const handleChangePartnerAssignedTo = async (event, value) => {
    if (value) {
      try {
        const resultChange = await changePartner(workticket.id, value.id);
        if (resultChange) {
          setPartnerAssigned(value);
          await refreshWorkticketData();
        }
      } catch (e) {
        logException(e, "Cannot change partner");
      }
    }
  };

  const handleChangeAssignedTo = async (event, value) => {
    const activeUser = JSON.parse(localStorage.getItem("user"));
    const removeAssigned = [];
    const addAssigned = [];

    const assignedToId = assignedTo.map((item) => item.id);
    const editAssignedToId = value.map((item) => item.id);

    assignedTo.forEach((item) => {
      if (!editAssignedToId.includes(item.id)) {
        removeAssigned.push(item.id);
      }
    });

    value.forEach((item) => {
      if (!assignedToId.includes(item.id)) {
        addAssigned.push(item.id);
      }
    });

    addAssigned.forEach(async (user) => {
      try {
        const resultAssign = await assignUserWorkticket(workticket.id, {
          user_id: user,
        });
        if (resultAssign) {
          await refreshWorkticketData();
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          setOpenMessageError(true);
          setMessage(e.response.data.message);
          const assignedRevert = [];
          usersAssigned.forEach((user) => {
            const userFound = users.find((uItem) => uItem.id === user.id);
            if (userFound) {
              assignedRevert.push(userFound);
            }
          });
          setAssignedTo(assignedRevert);
        }
        logException(e, "Cannot assign user to workticket");
      }
    });

    removeAssigned.forEach(async (user) => {
      try {
        // Safety so subs do not unassign there self
        if (activeUser.id !== user && activeUser.tier_classification !== 15) {
          await removeUserWorkticket(workticket.id, user);
          await refreshWorkticketData();
        } else {
          toast.warning("Can NOT unassign. Please contact account manager.");
          const findUser = usersList.find((item) => item.id === activeUser.id);
          value = [findUser, ...value];
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          setOpenMessageError(true);
          setMessage(e.response.data.message);
          const assignedRevert = [];
          usersAssigned.forEach((user) => {
            const userFound = users.find((uItem) => uItem.id === user.id);
            if (userFound) {
              assignedRevert.push(userFound);
            }
          });
          setAssignedTo(assignedRevert);
        }
        logException(e, "Cannot assign user to workticket");
      }
    });
    setAssignedTo(value);
  };

  const handleComplete = async () => {
    try {
      handleMarkDone();
      setStatusUpdateType(null);
    } catch (e) {
      logException(e, "Cannot mark completed");
    }
  };

  const handleSubmitClick = () => {
    setIsLoadingClockOut(true);
    if ([2, 4].includes(externalStatus)) {
      handleStopWork();
    } else {
      handleStopWork(1, 1);
    }
  };

  const handleValidationContinue = async () => {
    setIsLoadingValidation(true);
    if (typeValidation === 1) {
      // Clock In
      await handleStartWork(1);
    } else if (typeValidation === 2) {
      // Clock Out
      if (externalStatus) {
        await handleStopWork(1, 1);
      } else {
        await handleStopWork(1);
      }
    }
    setOpenValidation(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleStatusOpen = (type = 1) => {
    setOpenStatusUpdate(true);
    setStatusUpdateType(type);
  };

  const handleStatusUpdateClose = () => {
    setOpenStatusUpdate(false);
    setExternalStatus("");
    setStatusUpdateType(null);
  };

  const handleRecallClose = () => {
    setOpenRecall(false);
  };

  const handleValidationClose = () => {
    setOpenValidation(false);
    setIsLoadingValidation(false);
    setDisabledStart(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
    setDisabledStart(false);
  };

  const handleStartWork = async (skipValFlag = 0) => {
    setDisabledStart(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const resultClockActive = await getUserJobSchedules(
      user.id,
      workticket.job_number
    );

    if (
      resultClockActive.data.data.entries.length &&
      resultClockActive.data.data.entries[0].workticket &&
      resultClockActive.data.data.entries[0].workticket.id !== workticket.id
    ) {
      setMsgError(
        `User is already running a clock on ${resultClockActive.data.data.entries[0].workticket.number}.`
      );
      setOpenError(true);
      return false;
    }

    try {
      const data = {
        drive: 0,
        skipFlag: skipValFlag,
        latitude: positionLocation ? positionLocation.coords.latitude : null,
        longitude: positionLocation ? positionLocation.coords.longitude : null,
      };

      if ((!data.latitude || !data.latitude) && skipValFlag === 0) {
        setMsgValidation({
          title: "Not Detected",
          content:
            "We were not able to detect your location. Please enable your location to submit your request.",
        });
        setTypeValidation(1);
        setOpenValidation(true);
        const dataPreview = {
          latitude: null,
          longitude: null,
          method: "web",
          action: 1,
        };
        clockPreviewWorkticket(workticket.id, dataPreview);
        return;
      }

      if (skipValFlag === 0) {
        const resultDistanceGeo = getDistance(
          {
            latitude: data.latitude,
            longitude: data.longitude,
          },
          {
            latitude: workticket.job.latitude,
            longitude: workticket.job.longitude,
          }
        );

        if (resultDistanceGeo > 800 && skipValFlag === 0) {
          setMsgValidation({
            title: "Outside of Range",
            content:
              "Your location is outside of the accepted geofence range. This will result in ticket not being verified as completed at site.",
          });
          setTypeValidation(1);
          setOpenValidation(true);
          const dataPreview = {
            latitude: data.latitude,
            longitude: data.longitude,
            method: "web",
            action: 1,
          };
          clockPreviewWorkticket(workticket.id, dataPreview);
          return;
        }
      }

      const result = await clockInWorkticketNonStrict(workticket.id, data);
      await refreshWorkticketData();
      const schedule = result.data.data.schedule;
      const entry = schedule.entries.find(
        (item) => !item.clock_hours && item.user.id === user.id
      );
      if (entry.user.id === user.id) {
        setCurrentClock(entry);
        const entryInput = { ...entry, workticket: { ...workticket } };
        dispatchGlobalUi({
          type: "SET_TIMEKEEPING",
          timekeeping: entryInput,
        });
      }
      setActiveStart(true);
      setDisabledStart(false);
      setIsLoadingValidation(false);
    } catch (e) {
      let errorMsg = e.response.data.message;
      setMsgError(errorMsg);
      setOpenError(true);
    }
  };

  const handleStopWork = async (skipValFlag = 0, updateStatus = 0) => {
    try {
      if (!activeStart) {
        toast.warning("No clock is running.");
        return;
      }

      const data = {
        drive: 0,
        skipFlag: skipValFlag,
        latitude: positionLocation ? positionLocation.coords.latitude : null,
        longitude: positionLocation ? positionLocation.coords.longitude : null,
        external_status: statusUpdateType === 3 ? 4 : externalStatus,
        resolution: externalNote,
      };

      if (repairCategory) {
        data.RepairCategoryId = repairCategory;
      }

      if (repairCode) {
        data.RepairCodeId = repairCode;
      }

      if ((!data.latitude || !data.latitude) && skipValFlag === 0) {
        setMsgValidation({
          title: "Not Detected",
          content:
            "We were not able to detect your location. Please enable your location to submit your request.",
        });
        setTypeValidation(2);
        setOpenValidation(true);
        const dataPreview = {
          latitude: null,
          longitude: null,
          method: "web",
          action: 2,
        };
        clockPreviewWorkticket(workticket.id, dataPreview);
        return;
      }

      if (skipValFlag === 0) {
        const resultDistanceGeo = getDistance(
          {
            latitude: data.latitude,
            longitude: data.longitude,
          },
          {
            latitude: workticket.job.latitude,
            longitude: workticket.job.longitude,
          }
        );

        if (resultDistanceGeo > 800 && skipValFlag === 0) {
          setMsgValidation({
            title: "Outside of Range",
            content:
              "Your location is outside of the accepted geofence range. This will result in ticket not being verified as completed at site.",
          });
          setTypeValidation(2);
          setOpenValidation(true);
          const dataPreview = {
            latitude: data.latitude,
            longitude: data.longitude,
            method: "web",
            action: 2,
          };
          clockPreviewWorkticket(workticket.id, dataPreview);
          return;
        }
      }

      setDisabledStart(true);
      await clockOutWorkticket(
        workticket.id,
        currentClock.workticket_schedule_id,
        data
      );
      await refreshWorkticketData();
      setActiveStart(false);
      setCurrentClock(null);
      if (globalUi.timekeeping) {
        if (globalUi.timekeeping.workticket.id === workticket.id) {
          dispatchGlobalUi({
            type: "SET_TIMEKEEPING",
            timekeeping: null,
          });
        }
      }
      setDisabledStart(false);
      setIsLoadingValidation(false);
      setExternalStatus("");
      if (statusUpdateType === 3) {
        setOpenDone(true);
      } else if (updateStatus === 1) {
        handleAddNote();
        if (externalStatus === 2) {
          setOpenDone(true);
        }
        setOpenClockOut(false);
        setStatusUpdateType(null);
        setIsClosingClockOut(false);
        setDisabledStart(false);
        setIsLoadingClockOut(false);
      }
      setStatusUpdateType(null);
    } catch (e) {
      logException(e, "Cannot stop work");
    }
  };

  const handleChangeStatusExternal = (event) => {
    setExternalStatus(event.value);
  };

  const handleChangeCancellationReason = (event) => {
    setCancellationReason(event.value);
    setCancellationSubReason("");
  };

  const handleChangeCancellationSubReason = (event) => {
    setCancellationSubReason(event.value);
  };

  const handleChangeDueDate = async (event) => {
    if (!hasPermission(permissionWorkticket.EDIT, permissions)) {
      return;
    }
    if (workticket.start_date !== event.value) {
      try {
        const data = {
          start_date: moment(event).format("YYYY-MM-DD"),
        };
        workticket.start_date = moment(event).format("YYYY-MM-DD");
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: workticket,
        });
        await updateWorkticketDetails(workticket.id, data);
      } catch (e) {
        logException(e, "Cannot update workticket due date");
      }
    }
  };

  const handleSubmitCancellationReason = async (event) => {
    if (
      !hasPermission(
        permissionWorkticket.UPDATE_CANCELLATION_STATUS,
        permissions
      )
    ) {
      return;
    }
    if (workticket.cancellation_reason !== event.value) {
      setCancellationReasonSelected(event.value);
      try {
        const data = {
          cancellation_reason: event.value,
        };
        workticket.cancellation_reason = event.value;
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: workticket,
        });
        await updateWorkticketDetails(workticket.id, data);
      } catch (e) {
        logException(e, "Cannot update workticket cancellation reason");
      }
    }
  };

  const handleAddCompleteDate = () => {
    setDateDone(workticket.sub_completed_date);
  };

  const backDatePerm = hasPermission(
    permissionWorkticket.BACK_DATE,
    permissions
  );

  let minLimitDate = undefined;
  let minDateMessage = undefined;
  if (!backDatePerm) {
    minLimitDate = billingLimitDate(5);
    minDateMessage = "Date not allowed, contact financial/admin team.";
  }

  const markDonePerm = hasPermission(
    permissionWorkticket.MARK_DONE,
    permissions
  );

  const workticketsDisplayStatus = markDonePerm
    ? workticketsStatus.filter(
        (item) =>
          workticket.status === 4 ||
          (workticket.status !== 4 && item.value !== 4)
      )
    : workticketsRestrictStatus.filter(
        (item) =>
          (workticket.status === 4 && item.value !== 2) ||
          (workticket.status !== 4 && item.value !== 4)
      );

  const workticketsDisplayStatusClock = workticketsStatus.filter((item) =>
    [1, 4].includes(item.value)
  );

  if (isLoading || isLoadingUsers) {
    return <Skeleton animation="wave" variant="rect" height={"100%"} />;
  }

  const readOnlyWorkticket =
    (workticket?.recurrence_instance_id && !workticket?.instance_override) ||
    [2, 3, 5].includes(workticket?.status);

  const handleChangeStatus = async () => {
    setIsLoadingStatusU(true);
    if (workticket.status !== externalStatus) {
      if (externalStatus === 2) {
        setOpenDone(true);
      } else {
        try {
          let dataCancellation = {};
          if (externalStatus === 3) {
            dataCancellation = {
              cancellation_reason: cancellationReason,
              cancellation_sub_reason: cancellationSubReason,
              cancellation_note: cancellationReasonNote,
            };
            setCancellationReasonSelected(cancellationReason);
            setCancellationSubReasonSelected(cancellationSubReason);
          }
          let data = {
            status: externalStatus,
            ...dataCancellation,
          };
          const position = await getGeoLocation();
          if (position) {
            data = {
              ...data,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
          }
          workticket.status = externalStatus;
          dispatchContext({
            type: "SET_WORKTICKET",
            workticket: workticket,
          });
          await updateWorkticketStatus(workticket.id, data);
          setMsgSuccess("Workticket status has been updated.");
          setOpenSuccess(true);
          setStatusUpdateType(null);
        } catch (e) {
          logException(e, "Cannot update workticket status");
        }
      }
      setOpenStatusUpdate(false);
      setExternalStatus("");
      setIsLoadingStatusU(false);
    }
    setIsLoadingStatusU(false);
  };

  const handleRecall = async () => {
    if ([2, 4].includes(workticket.status)) {
      try {
        setIsLoadingRecall(true);
        const data = {
          note: externalNote,
        };

        await recallWorkticketStatus(workticket.id, data);
        // Set to In Progress status
        workticket.status = 1;
        workticket.recalled = 1;
        workticket.recalled_date = moment().format("YYYY-MM-DD");
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: workticket,
        });
        setMsgSuccess("Workticket has been recalled.");
        setOpenSuccess(true);
        setExternalNote("");
        setIsLoadingRecall(false);
        setOpenRecall(false);
      } catch (e) {
        logException(e, "Cannot recall workticket");
      }
    }
  };

  const handleDoneClose = () => {
    setOpenDone(false);
    setDateDone(null);
    setConfirmationDate(false);
  };

  const handleMarkDone = async () => {
    const doneStatus = 2;
    const completedStatus = 4;
    try {
      setConfirmationIsLoading(true);
      let data = { status: doneStatus };
      let completedDate = moment().format("YYYY-MM-DD");
      if (hasPermission(permissionWorkticket.SET_COMPLETED_DATE, permissions)) {
        completedDate = moment(dateDone).format("YYYY-MM-DD");
        data = {
          ...data,
          completed_date: completedDate,
        };
      }
      const position = await getGeoLocation();
      if (position) {
        data = {
          ...data,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      }
      await updateWorkticketStatus(workticket.id, data);
      await refreshWorkticketData();
      const permDone = hasPermission(
        permissionWorkticket.MARK_DONE,
        permissions
      );

      const onTime = moment(completedDate).isSameOrBefore(
        moment(workticket.start_date)
      );
      workticket.status = permDone ? doneStatus : completedStatus;
      workticket.completed_date = permDone ? completedDate : null;
      workticket.compliance_ontime = onTime ? 1 : 0;

      dispatchContext({
        type: "SET_WORKTICKET",
        workticket: workticket,
      });
      setConfirmationIsLoading(false);
      setOpenDone(false);
      setMsgSuccess(`Workticket has been mark verified.`);
      setOpenSuccess(true);
      setDateDone(null);
      setConfirmationDate(false);
    } catch (e) {
      if (e.message.toUpperCase().includes("CODE 400")) {
        setConfirmationIsLoading(false);
        setOpenDone(false);
        setDateDone(null);
        setConfirmationDate(false);
        setMsgError(
          "The timekeeping records do not match the payment amount for the service, Please update in order to mark the worktickets as verified"
        );
        setOpenError(true);
      } else {
        logException(e, "Cannot mark workticket as verified");
      }
    }
  };

  const handleMarkDoneConfirmation = () => {
    setConfirmationDate(true);
  };

  const handleMarkCompleted = () => {
    if (
      customerApplyRepair.includes(parseInt(workticket?.external?.customer_id))
    ) {
      setMarkCompleted(true);
      setOpenRepairDialog(true);
    } else {
      setMarkCompleted(false);
      setStatusUpdateType(3);
      handleStopWork();
    }
  };

  const handleRepairDialogSubmit = () => {
    setMarkCompleted(false);
    setStatusUpdateType(3);
    handleStopWork();
    setOpenRepairDialog(false);
  };

  const handleRepairCategoryNext = () => {
    setExternalStatus("");
    setExternalNote("");
    setOpenRepairDialog(false);
    if (workticket?.project?.type !== TEMP_STAFFING_PROJECT) {
      setOpenClockOut(true);
    } else {
      handleStopWork(1, 1);
    }
    setDisabledStart(true);
  };

  const handleClockOutOpen = () => {
    if (
      customerApplyRepair.includes(parseInt(workticket?.external?.customer_id))
    ) {
      setOpenRepairDialog(true);
    } else {
      setExternalStatus("");
      setExternalNote("");
      if (workticket?.project?.type !== TEMP_STAFFING_PROJECT) {
        setOpenClockOut(true);
      } else {
        handleStopWork(1, 1);
      }
      setDisabledStart(true);
    }
  };

  const handleChangeStatusPT = (event) => {
    setExternalStatus(event.value);
  };

  const handleRepairCategoryChange = (event) => {
    setRepairCategory(event.value);
    const repairCodeOptions = repairCategoryOptions.find(
      (item) => item.value === event.value
    );
    setRepairCodeOptions(repairCodeOptions.codes);
  };

  const handleRepairCodeChange = (event) => {
    setRepairCode(event.value);
  };

  const handleClockOutBack = () => {
    setOpenClockOut(false);
    setOpenRepairDialog(true);
  };

  const handleChangeNotePT = (event, value) => {
    setExternalNote(event.value);
  };

  const handleChangeNoteCancellation = (event, value) => {
    setCancellationReasonNote(event.value);
  };

  const handleClockOutConfirmationRevert = () => {
    setIsClosingClockOut(false);
  };

  const handleClockOutClose = () => {
    setOpenClockOut(false);
    setExternalStatus("");
    setExternalNote("");
    setStatusUpdateType(null);
    setIsClosingClockOut(false);
    setDisabledStart(false);
  };

  const handleAddNote = async () => {
    if (externalNote !== "") {
      const data = {
        body: externalNote,
      };
      try {
        const result = await addWorkticketComment(workticket.id, data);
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: {
            ...workticket,
            comments: result.data.data.workticket.comments,
          },
        });
        setExternalNote("");
      } catch (e) {
        logException(e, "Cannot add comment");
      }
    }
  };

  const handleAccept = async () => {
    setDisabledStart(true);
    try {
      await acceptWorkticketCO(workticket?.id);
      workticket.status = 0;
      dispatchContext({
        type: "SET_WORKTICKET",
        workticket: workticket,
      });
    } catch (e) {
      logException(e, "Cannot accept workorder");
    }
    setDisabledStart(false);
  };

  const handleApproveOpen = async () => {
    try {
      let errorApprove = [];
      setOpenApprove(true);
      setIsLoadingStatusU(true);

      const { data } = await getWorkticketDetails(workticket.id);
      const wtNte = workticket.external?.nte ?? 0;
      if (
        (!data.data.workticket.bill.total_amount ||
          Number(data.data.workticket.bill.total_amount) === 0) &&
        wtNte > 0
      ) {
        errorApprove = [...errorApprove, "Missing billing amount (AR)"];
      }

      if (
        Number(workticket.external?.perform_by) === 2 &&
        (!data.data.workticket.payment.user_id ||
          data.data.workticket.payment.user_id === 0)
      ) {
        errorApprove = [...errorApprove, "Missing partner assignment "];
      }

      setApproveValidation(errorApprove);
      setIsLoadingStatusU(false);
    } catch (e) {
      logException(e, "Cannot approve workticket");
    }
  };

  const handleApproveClose = () => {
    setOpenApprove(false);
  };

  const handleApprove = async () => {
    try {
      setIsLoadingStatusU(true);
      await approveWorkticketCO(workticket.id);
      await refreshWorkticketData();
      setOpenApprove(false);
      setIsLoadingStatusU(false);
    } catch (e) {
      logException(e, "Cannot approve workticket");
    }
  };

  const handleChangePerformBy = async (event) => {
    setPerformBy(event.value);
    const dataUpdate = {
      perform_by: event.value,
    };
    await updatePerformType(workticket.id, dataUpdate);
  };

  const handleChangePerformByPayable = async (event) => {
    setPerformByPayable(event.value);
    const dataUpdate = {
      perform_by_payable: event.value,
    };
    await updatePerformType(workticket.id, dataUpdate);
  };

  const externalNumber = workticket.number.replace(/^WC/, "");

  const preventativeService = Array.isArray(workticket?.preventative_service)
    ? workticket.preventative_service[0]
    : null;

  const serviceType = preventativeService
    ? Number(preventativeService.service_type)
    : null;

  const serviceTypeDetails = getServiceTypeDetails(serviceType);

  return (
    <>
      <Box className={classes.containerMainInformation}>
        <Box className={classes.containerInformation}>
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="workticket_number"
            label="Workticket Number"
            value={workticket.number}
            styleOverride={{ fontWeight: "bold", cursor: "not-allowed" }}
            readonly={true}
          />
          <Box className={classes.serviceTypeContainer}>
            <BadgeComponent
              icon={serviceTypeDetails.icon}
              label={serviceTypeDetails.label}
              height={25}
              minWidth={120}
              marginRight={5}
              paddingLeft={10}
              border="1px solid #BAE7FA"
              borderRadius={4}
              fontSize={14}
              fontWeight="normal"
              color="#17457A"
              backgroundColor="#EDF5F8"
            />
          </Box>

          {hasPermission(permissionWorkticket.REQUESTS, permissions) ? (
            <>
              <FormSelect
                gridSizes={[{ size: "md", val: 12 }]}
                name="perform_by"
                label="Performed By"
                options={performTypeOptions}
                value={performBy}
                handleBlur={handleChangePerformBy}
                disabled={[2, 3, 4, 5].includes(workticket.status)}
              />
              {performBy === 1 ? (
                <FormSelect
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="perform_by_payable"
                  label="Payable Hours"
                  options={performTypePayableOptions}
                  value={performByPayable}
                  handleBlur={handleChangePerformByPayable}
                  disabled={[2, 3, 4, 5].includes(workticket.status)}
                />
              ) : null}
            </>
          ) : null}

          {performBy === 1 ? (
            <>
              {![2, 3, 5].includes(workticket.status) &&
              hasPermission(
                permissionWorkticket.ASSIGN_SCHEDULE,
                permissions
              ) ? (
                <FormSelectChipsAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={assignedToInHouse}
                  name="users"
                  label="Assigned To"
                  handleChange={handleChangeAssignedTo}
                  multiple={true}
                  value={assignedTo}
                />
              ) : (
                <>
                  {Boolean(assignedTo.length) && (
                    <InputLabel className={classes.inputLabel}>
                      Assigned To
                    </InputLabel>
                  )}
                  {assignedTo.map((option, index) => (
                    <Chip
                      disabled={false}
                      key={index}
                      data-tag-index={index}
                      tabIndex={-1}
                      avatar={<Avatar>{option.first_name[0]}</Avatar>}
                      label={`${option.first_name} ${option.last_name}`}
                      className={classes.chipAuto}
                    />
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              <WorkticketBillingPartner onlyPortfolio={true} />
              {(workticket?.project?.type === PORTFOLIO_PROJECT ||
                workticket?.project?.type === CONTRACT_PERIODIC) &&
              workticket.preventative_service &&
              workticket.preventative_service[0].work_type === 2 ? (
                <>
                  {!permOnlyAP && !permAP ? null : (![2, 3, 5].includes(
                      workticket.status
                    ) ||
                      (workticket.status === 2 &&
                        workticket?.payment?.amount_paid === 0 &&
                        [0, 1].includes(workticket.payment.status))) &&
                    hasPermission(
                      permissionWorkticket.ASSIGN_SCHEDULE,
                      permissions
                    ) &&
                    hasPermission(permissionWorkticket.EDIT, permissions) ? (
                    <FormSelectChipsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={partners}
                      name="partner"
                      label="Partner Responsible"
                      handleChange={handleChangePartnerAssignedTo}
                      value={partnerAssigned}
                      readonly={readOnlyWorkticket}
                    />
                  ) : (
                    <>
                      {Boolean(partnerAssigned) && (
                        <InputLabel className={classes.inputLabel}>
                          Partner Responsible
                        </InputLabel>
                      )}
                      <Box className={classes.chipContainer}>
                        <Chip
                          disabled={false}
                          data-tag-index={1}
                          tabIndex={-1}
                          avatar={
                            <Avatar>{partnerAssigned?.first_name[0]}</Avatar>
                          }
                          label={`${partnerAssigned?.first_name} ${partnerAssigned?.last_name}`}
                          className={classes.chipAutoDis}
                        />
                      </Box>
                    </>
                  )}
                  {(![2, 3, 5].includes(workticket.status) ||
                    (workticket.status === 2 &&
                      workticket?.payment?.amount_paid === 0 &&
                      [0, 1].includes(workticket.payment.status))) &&
                  hasPermission(
                    permissionWorkticket.ASSIGN_SCHEDULE,
                    permissions
                  ) ? (
                    <FormSelectChipsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={usersList}
                      name="users"
                      label="Assigned To"
                      handleChange={handleChangeAssignedTo}
                      multiple={true}
                      value={assignedTo}
                    />
                  ) : (
                    <>
                      {Boolean(assignedTo.length) ? (
                        <>
                          <InputLabel className={classes.inputLabel}>
                            Assigned To
                          </InputLabel>
                          <Box className={classes.chipContainer}>
                            {assignedTo.map((option, index) => (
                              <Chip
                                disabled={false}
                                key={index}
                                data-tag-index={index}
                                tabIndex={-1}
                                avatar={<Avatar>{option.first_name[0]}</Avatar>}
                                label={`${option.first_name} ${option.last_name}`}
                                className={classes.chipAutoDisMultiple}
                              />
                            ))}
                          </Box>
                        </>
                      ) : null}
                    </>
                  )}
                </>
              ) : (![2, 3, 5].includes(workticket.status) ||
                  (workticket.status === 2 &&
                    workticket?.payment?.amount_paid === 0 &&
                    [0, 1].includes(workticket.payment.status))) &&
                hasPermission(
                  permissionWorkticket.ASSIGN_SCHEDULE,
                  permissions
                ) ? (
                <FormSelectChipsAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={usersList}
                  name="users"
                  label="Assigned To"
                  handleChange={handleChangeAssignedTo}
                  multiple={true}
                  value={assignedTo}
                  readonly={readOnlyWorkticket}
                />
              ) : (
                <>
                  {Boolean(assignedTo.length) && (
                    <InputLabel className={classes.inputLabel}>
                      Assigned To
                    </InputLabel>
                  )}
                  {assignedTo.map((option, index) => (
                    <Chip
                      disabled={false}
                      key={index}
                      data-tag-index={index}
                      tabIndex={-1}
                      avatar={<Avatar>{option.first_name[0]}</Avatar>}
                      label={`${option.first_name} ${option.last_name}`}
                      className={classes.chipAuto}
                    />
                  ))}
                </>
              )}
            </>
          )}
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="subject"
            label="Summary"
            value={workticket.subject}
            handleBlur={handleBlur}
            readonly={
              !hasPermission(permissionWorkticket.EDIT, permissions) ||
              readOnlyWorkticket
            }
          />
          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="description"
            label="Description"
            value={workticket?.description ?? ""}
            multiline={true}
            rows={4}
            handleBlur={handleBlur}
            readonly={
              !hasPermission(permissionWorkticket.EDIT, permissions) ||
              readOnlyWorkticket
            }
          />
          {parseInt(workticket?.status) !== 6 ? (
            <Box className={classes.containerPriority}>
              <Grid container spacing={1} justifyContent="flex-start">
                <Grid item xs={6} className={classes.containerBadges}>
                  <WorkticketStatusChip
                    status={workticket.status}
                    dueDate={workticket.start_date}
                    archived={0}
                  />
                  {([2, 4, 5].includes(workticket.status) &&
                    workticket.compliance_checked) ||
                  workticket.recalled ? (
                    <WorkticketComplianceChip
                      onSite={workticket.compliance_onsite}
                      onTime={workticket.compliance_ontime}
                      hasDocuments={workticket.compliance_document}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  {[4].includes(workticket.status) &&
                  (parseInt(workticket?.external?.approval) === 1 ||
                    ["Verified", "CheckedOut"].includes(
                      workticket?.external?.status_external
                    )) &&
                  hasPermission(permissionWorkticket.MARK_DONE, permissions) ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth={true}
                      className={classNames(
                        classes.button,
                        classes.buttonOutlined
                      )}
                      onClick={handleStatusOpen}
                      style={{ marginBottom: 3 }}
                      disabled={activeStart}
                    >
                      Update Status
                    </Button>
                  ) : null}
                  {[0, 1, 6].includes(workticket.status) ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth={true}
                      className={classNames(
                        classes.button,
                        classes.buttonOutlined
                      )}
                      onClick={() => handleStatusOpen(2)}
                      style={{ marginBottom: 3 }}
                      disabled={activeStart}
                    >
                      Update Status
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          ) : null}
          {hasPermission(
            permissionWorkticket.REQUESTS_VIEW_LINK,
            permissions
          ) ? (
            <>
              <InputLabel className={classes.inputLabel}>
                Workorder Number
              </InputLabel>
              <Typography className={classes.linkTipo}>
                <a
                  href={
                    window.location.hostname === "app.encompassonsite.com"
                      ? `https://am-desktop.corrigopro.com/ServiceChat/Chat?filter=${externalNumber}`
                      : `https://am-desktop.corrigopro.com/ServiceChat/Chat?filter=${externalNumber}`
                  }
                  target="_blank"
                  rel="noreferrer"
                  className={classes.linkLabel}
                >
                  {externalNumber}
                  <IconLink className={classes.linkIcon} />
                </a>
              </Typography>
            </>
          ) : null}

          {role?.category !== "subcontractor" &&
          [3].includes(workticket.status) &&
          Number(workticket.cancellation_reviewed) === 1 ? (
            <Box>
              <FormSelect
                gridSizes={[{ size: "md", val: 12 }]}
                name="cancellation_reason"
                label="Cancellation Reason"
                options={cancelReasonOptions}
                value={cancellationReasonSelected}
                handleBlur={handleSubmitCancellationReason}
                changeValueControlled={true}
                readonly={true}
              />
              <Box pt={2}>
                <FormSelect
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="cancellation_sub_reason"
                  label="Sub Reason"
                  options={
                    cancelSubReasonOptions.length > 0
                      ? cancelSubReasonOptions
                      : workticketsCancellationReasonOptions
                          .find(
                            (reason) =>
                              reason.value === cancellationReasonSelected
                          )
                          ?.subreasons.map((subreason) => ({
                            value: subreason.value,
                            label: subreason.label,
                          })) || []
                  }
                  value={cancellationSubReasonSelected}
                  handleBlur={handleSubmitCancellationReason}
                  changeValueControlled={true}
                  readonly={true}
                />
              </Box>
            </Box>
          ) : null}

          <FormInput
            gridSizes={[{ size: "md", val: 12 }]}
            name="job_number"
            label="Job"
            value={`${workticket.job.job_number} - ${workticket.job.job_description}`}
            readonly={true}
          />
          <FormInputDateAdo
            name="created_at"
            label="Date Created"
            value={workticket.created_at}
            handleChange={handleChangeDueDate}
            disabled={true}
          />
          <FormInputDateAdo
            name="start_date"
            label="Due Date"
            value={workticket.start_date}
            handleChange={handleChangeDueDate}
            disabled={true}
          />
          {workticket?.external &&
          hasPermission(permissionWorkticket.WALGREENS_DATA, permissions) ? (
            <>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="trade"
                label="Accept/Reject By"
                value={moment(workticket?.external?.accept_by)
                  .tz(workticket?.job?.timezone)
                  .format("MM/DD/YYYY hh:mm A")}
                readonly={true}
              />
              <InputLabel className={classes.inputLabel}>
                Partner Coverage
              </InputLabel>
              <Box className={classes.partnerCoverageContainer}>
                {partners.length ? (
                  <>
                    <CheckCircleIcon
                      style={{ marginRight: 10, color: "#328914" }}
                    />
                    <Typography variant="body2" noWrap>
                      Yes
                    </Typography>
                  </>
                ) : (
                  <>
                    <CancelIcon style={{ marginRight: 10, color: "#D9D9D9" }} />
                    <Typography variant="body2" noWrap>
                      No
                    </Typography>
                  </>
                )}
              </Box>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="trade"
                label="Trade Needed"
                value={workticket?.external?.trade}
                readonly={true}
              />
              {hasPermission(
                permissionWorkticket.WALGREENS_NTE,
                permissions
              ) ? (
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="nte"
                  label="NTE"
                  value={`$${parseFloat(workticket?.external?.nte).toFixed(2)}`}
                  readonly={true}
                />
              ) : null}
            </>
          ) : null}
        </Box>
        <Box className={classes.containerInformationActions}>
          {[6].includes(workticket.status) ? (
            <Grid container spacing={1} justifyContent="flex-end">
              <>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    onClick={() => handleAccept()}
                    className={classes.buttonAccept}
                    disabled={disabledStart}
                  >
                    Accept
                  </Button>
                </Grid>
              </>
            </Grid>
          ) : null}
          {[0, 1].includes(workticket.status) &&
          parseInt(workticket.external.approval) === 0 ? (
            <Grid container spacing={1} justifyContent="flex-end">
              <>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={true}
                    onClick={handleApproveOpen}
                    className={classes.buttonCompleted}
                  >
                    Approve
                  </Button>
                </Grid>
              </>
            </Grid>
          ) : null}
          {![2, 3, 4, 5, 6].includes(workticket.status) &&
          parseInt(workticket?.external?.approval) === 1 ? (
            <Grid container spacing={1} justifyContent="flex-end">
              <>
                <Grid item xs={6}>
                  {activeStart ? (
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth={true}
                      onClick={() => handleClockOutOpen()}
                      className={classes.buttonStop}
                      disabled={disabledStart}
                      startIcon={hasGeo ? <LocationIcon /> : null}
                    >
                      Stop
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="secondary"
                      fullWidth={true}
                      onClick={() => handleStartWork()}
                      className={classes.buttonStart}
                      disabled={disabledStart}
                      startIcon={hasGeo ? <LocationIcon /> : null}
                    >
                      Start
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth={true}
                    onClick={handleMarkCompleted}
                    className={classes.buttonCompleted}
                    disabled={!activeStart}
                  >
                    Mark Completed
                  </Button>
                </Grid>
              </>
            </Grid>
          ) : null}
          <ErrorMessageDialog
            title={""}
            open={openMessageError}
            handleClose={closeMessageError}
            message={message}
          />
        </Box>
      </Box>

      <Dialog
        open={openStatusUpdate}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingStatusU ? (
            <>
              <DialogCloseIcon handleClose={handleStatusUpdateClose} />
              <DialogTitle title="Workticket" icon={<TicketIcon />} />
              <Box className={classes.wrapperDialogStatus}>
                <FormSelect
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="status"
                  label="Status(*)"
                  options={workticketsDisplayStatus
                    .filter((item) => item.value !== workticket.status)
                    .filter(
                      (item) =>
                        (statusUpdateType === 1 ? 2 : 3) === item.value ||
                        ([2, 3].includes(item.value) && workticket.status === 4)
                    )}
                  value={externalStatus}
                  handleBlur={handleChangeStatusExternal}
                  changeValueControlled={true}
                />
                {externalStatus === 3 ? (
                  <Box pt={2}>
                    <FormSelect
                      gridSizes={[{ size: "md", val: 12 }]}
                      name="cancellation_reason"
                      label="Reason(*)"
                      options={workticketsCancellationReasonOptions}
                      value={cancellationReason}
                      handleBlur={handleChangeCancellationReason}
                      changeValueControlled={true}
                    />
                    {cancellationReason && (
                      <Box pt={2}>
                        <FormSelect
                          gridSizes={[{ size: "md", val: 12 }]}
                          name="cancellation_sub_reason"
                          label="Sub Reason(*)"
                          options={
                            workticketsCancellationReasonOptions
                              .find(
                                (reason) => reason.value === cancellationReason
                              )
                              ?.subreasons.map((subreason) => ({
                                value: subreason.value,
                                label: subreason.label,
                              })) || []
                          }
                          value={cancellationSubReason}
                          handleBlur={handleChangeCancellationSubReason}
                          changeValueControlled={true}
                        />
                      </Box>
                    )}
                    <Box pt={2}>
                      <FormInput
                        gridSizes={[{ size: "md", val: 12 }]}
                        name="cancellation_reason_note"
                        label="Note(*)"
                        placeholder="Type note here"
                        value={cancellationReasonNote}
                        multiline={true}
                        rows={4}
                        handleBlur={handleChangeNoteCancellation}
                      />
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingStatusU ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleStatusUpdateClose}
              className={classes.buttonOutlinedDialog}
              variant="outlined"
              size="large"
              color="primary"
              disabled={isLoadingStatusU}
            >
              Cancel
            </Button>
            <Button
              onClick={handleChangeStatus}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={
                isLoadingStatusU ||
                (!externalStatus && externalStatus !== 0) ||
                (externalStatus === 3 && !cancellationReason) ||
                (externalStatus === 3 && !cancellationReasonNote)
              }
            >
              {statusUpdateType === 1 ? "Completed" : "Update"}
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        open={openRecall}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingRecall ? (
            <>
              <DialogCloseIcon handleClose={handleRecallClose} />
              <DialogTitle title="Recall Workticket" icon={<TicketIcon />} />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="external_note"
                label="Reason"
                placeholder="Type note here"
                value={externalNote}
                multiline={true}
                rows={4}
                handleBlur={handleChangeNotePT}
              />
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingRecall ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleRecallClose}
              className={classes.buttonOutlinedDialog}
              variant="outlined"
              size="large"
              color="primary"
              disabled={isLoadingStatusU}
            >
              Cancel
            </Button>
            <Button
              onClick={handleRecall}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={externalNote.length < 3}
            >
              Recall
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        open={openValidation}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingValidation ? (
            <>
              <DialogCloseIcon handleClose={handleValidationClose} />
              <DialogTitle
                title={`Location ${msgValidation?.title}`}
                icon={<LocationOff fontSize="large" />}
              />
              <Box className={classes.wrapperDialogValidation}>
                <Typography variant="body2">
                  {msgValidation?.content}
                </Typography>
              </Box>
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingValidation ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleValidationClose}
              className={classes.buttonOutlinedDialog}
              variant="outlined"
              size="large"
              color="secondary"
            >
              Go Back
            </Button>
            {msgValidation?.title !== "Not Detected" ? (
              <Button
                onClick={handleValidationContinue}
                className={classes.buttonDialog}
                color="secondary"
                variant="contained"
                size="large"
              >
                Continue
              </Button>
            ) : null}
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        open={openDone}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          <DialogCloseIcon handleClose={handleDoneClose} />
          <DialogTitle title="Workticket" icon={<TicketIcon />} />
          {hasPermission(
            permissionWorkticket.SET_COMPLETED_DATE,
            permissions
          ) ? (
            <>
              {confirmationDate && dateDone ? (
                !confirmationIsLoading ? (
                  <DialogMessage>
                    Are you sure, the date{" "}
                    {moment(dateDone).format("MM/DD/YYYY")} is the date and
                    month that billing will be allocated to?
                  </DialogMessage>
                ) : (
                  <Box className={classes.formContainer}>
                    <LoadingStateHorizontal
                      isVisible
                      style={classes.centerLoading}
                    />
                  </Box>
                )
              ) : (
                <>
                  <DialogMessage>
                    Are you sure you want to mark workticket as{" "}
                    {markDonePerm ? `verified` : `completed`}?
                  </DialogMessage>
                  {workticket.sub_completed_date && (
                    <Alert
                      severity="info"
                      className={classes.alertContainer}
                      action={
                        <Button
                          color="primary"
                          size="small"
                          variant="contained"
                          onClick={handleAddCompleteDate}
                        >
                          Use Date +
                        </Button>
                      }
                    >
                      Marked completed on{" "}
                      {convertDateFormatField(workticket.sub_completed_date)}
                    </Alert>
                  )}

                  <Box className={classes.formContainer}>
                    <FormInputDateAdo
                      name="date"
                      label="Verified date*"
                      value={dateDone}
                      minDate={minLimitDate}
                      minDateMessage={minDateMessage}
                      handleChange={setDateDone}
                      disableFuture={true}
                      errorMessage={"Please select a valid work verify date"}
                    />
                    <Typography
                      variant="caption"
                      className={classes.formInfoText}
                    >
                      *If applicable, billing will be tied to this time frame.
                    </Typography>
                  </Box>
                </>
              )}
            </>
          ) : (
            <DialogMessage>
              Are you sure you want to mark workticket as{" "}
              {markDonePerm ? `verified` : `completed`}?
            </DialogMessage>
          )}
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleDoneClose}
            className={classes.buttonOutlinedDialog}
            variant="outlined"
            size="large"
            color="primary"
          >
            Cancel
          </Button>
          {hasPermission(
            permissionWorkticket.SET_COMPLETED_DATE,
            permissions
          ) ? (
            <Button
              onClick={
                confirmationDate ? handleComplete : handleMarkDoneConfirmation
              }
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={!dateDone || confirmationIsLoading}
            >
              {confirmationDate ? `Yes` : `Verify`}
            </Button>
          ) : (
            <Button
              onClick={handleComplete}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
            >
              Okay
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openClockOut}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingClockOut ? (
            <>
              {!isClosingClockOut ? (
                <DialogCloseIcon
                  handleClose={handleClockOutConfirmationClose}
                />
              ) : null}
              <DialogTitle
                title={`Workticket - ${workticket.number}`}
                icon={<TicketIcon />}
              />
              {!isClosingClockOut ? (
                <Box className={classes.wrapperDialogStatus}>
                  <Box className={classes.wrapperDialogValidation}>
                    <Typography variant="body2">
                      Please update the status of the workticket
                    </Typography>
                  </Box>
                  <FormSelect
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="external_status_clock_out"
                    label="Select status"
                    options={workticketsDisplayStatusClock}
                    value={externalStatus}
                    handleBlur={handleChangeStatusPT}
                    changeValueControlled={true}
                  />
                  {[2, 4].includes(externalStatus) ? (
                    <Box className={classes.wrapperDialogValidation}>
                      <Typography variant="body2">
                        Would you like to add a note, before marking the
                        workticket as completed?
                      </Typography>
                    </Box>
                  ) : null}
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="external_note"
                    label="Note"
                    placeholder="Type note here"
                    value={externalNote}
                    multiline={true}
                    rows={4}
                    handleBlur={handleChangeNotePT}
                  />
                </Box>
              ) : (
                <Box className={classes.wrapperDialogStatus}>
                  <Box className={classes.wrapperDialogValidation}>
                    <Typography variant="body2">
                      Are you sure you want to cancel, the status of the
                      workticket won't be updated?
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingClockOut && !isClosingClockOut ? (
          <DialogActions className={classes.wrapperDialogAction}>
            {customerApplyRepair.includes(
              parseInt(workticket?.external?.customer_id)
            ) ? (
              <Button
                onClick={handleClockOutBack}
                className={classes.buttonOutlinedDialog}
                color="secondary"
                variant="outlined"
                size="large"
              >
                Back
              </Button>
            ) : null}
            <Button
              onClick={handleSubmitClick}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={isLoadingClockOut || !externalStatus}
            >
              Submit
            </Button>
          </DialogActions>
        ) : null}
        {isClosingClockOut ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleClockOutConfirmationRevert}
              className={classes.buttonOutlinedDialog}
              color="secondary"
              variant="outlined"
              size="large"
            >
              No
            </Button>
            <Button
              onClick={handleClockOutClose}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
            >
              Yes
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        open={openApprove}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingStatusU ? (
            <>
              <DialogCloseIcon handleClose={handleApproveClose} />
              <DialogTitle title="Workticket" icon={<TicketIcon />} />
              {Number(workticket?.external?.perform_by) === 1 ? (
                <Typography variant="body2">
                  Please verify the set up is correct. This will enable the
                  workticket for work.
                </Typography>
              ) : (
                <Typography variant="body2">
                  Please verify the partner, partner payment and billing are
                  correct. This will enable the workticket for work.
                </Typography>
              )}
              {approveValidation?.map((item, index) => (
                <Box
                  key={`ai-${index}`}
                  className={classes.wrapperItemValidation}
                >
                  * {item}
                </Box>
              ))}
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingStatusU ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleApproveClose}
              className={classes.buttonOutlinedDialog}
              variant="outlined"
              size="large"
              color="primary"
              disabled={isLoadingStatusU}
            >
              Cancel
            </Button>
            <Button
              onClick={handleApprove}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={
                isLoadingStatusU ||
                (approveValidation && Boolean(approveValidation.length))
              }
            >
              {Boolean(approveValidation?.length) ? "*" : ""}
              Approve
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        open={openRepairDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          <>
            <DialogCloseIcon handleClose={handleRepairDialogClose} />
            <DialogTitle
              title={`Workticket - ${workticket.number}`}
              icon={<TicketIcon />}
            />
            <Box className={classes.wrapperDialogStatus}>
              <Box className={classes.wrapperDialogValidation}>
                <Typography variant="body2">
                  In order to clock out this ticket have to select a category
                  and a repair code bellow
                </Typography>
              </Box>
              <FormSelect
                gridSizes={[{ size: "md", val: 12 }]}
                name="external_repair_category"
                label="Category*"
                options={repairCategoryOptions}
                value={repairCategory}
                handleBlur={handleRepairCategoryChange}
                changeValueControlled={true}
              />
              <Box className={classes.repairSpace} />
              <FormSelect
                gridSizes={[{ size: "md", val: 12 }]}
                name="external_repair_code"
                label="Repair Code"
                options={repairCodeOptions}
                value={repairCode}
                handleBlur={handleRepairCodeChange}
                changeValueControlled={true}
              />
            </Box>
          </>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          {markCompleted ? (
            <Button
              onClick={handleRepairDialogSubmit}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={!repairCode}
            >
              Submit
            </Button>
          ) : (
            <Button
              onClick={handleRepairCategoryNext}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={!repairCategory}
            >
              Next
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <MessageDialog
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title="Success"
        message={msgSuccess}
      />
      <ErrorDialog
        open={openError}
        handleClose={handleCloseError}
        title="Error"
        message={msgError}
      />
    </>
  );
};

export default withRouter(WorkticketInformationCO);
